import React from "react";

import MainLayout from "../componentsTailwind/MainLayout";

import config from "../configs";
import { Helmet } from "react-helmet";
import BreadcrumbsV2 from "../components/breadcrumbsV2";

export default () => {
  return (
    <MainLayout>
      <Helmet>
        <title>Ochrana oznamovatelů</title>
        <meta property="og:image" content={`${config.serverUrl}/images/og-image-hp.png`} />
        <meta name="robots" content="noindex nofollow" />
        <link rel="canonical" href="https://www.mnd.cz/ochrana-oznamovatelu"></link>
      </Helmet>

      <div className="max-w-screen-xl mx-auto px-4 pb-2">
        <BreadcrumbsV2
          pages={[
            {
              name: "Ochrana oznamovatelů",
              link: "/ochrana-oznamovatelu",
            },
          ]}
          color={"grey.dark"}
        />
      </div>

      <div className="max-w-[700px] w-full mx-auto px-5 text-expertise-gray text-[15px] mb-[122px]">
        <h1 className="text-H1 text-exploratory-green-400 mt-9 mb-7">Ochrana oznamovatelů</h1>
        <h2 className="text-H2 mb-2">Jak podat oznámení o&nbsp;možném protiprávním jednání?</h2>
        <div>Pravidla pro&nbsp;podání oznámení ve&nbsp;smyslu zákona č. 171/2023 Sb., o&nbsp;ochraně oznamovatelů (dále jen „Zákon“):</div>
        <h3 className="text-H4 my-3">Kdo může oznámení podat?</h3>
        <div>
          <div className="mb-2">Každý, kdo</div>
          <ul>
            <li>byť zprostředkovaně vykonával nebo vykonává práci nebo jiné obdobné činnosti, pro&nbsp;společnost MND Energie a.s. nebo</li>
            <li>je nebo byl se&nbsp;společností MND Energie a.s. v&nbsp;kontaktu v&nbsp;souvislosti s&nbsp;výkonem práce nebo jiné obdobné činnosti</li>
          </ul>
          <div className="mb-2">a&nbsp;v&nbsp;souvislosti s&nbsp;tím se dozvěděl o&nbsp;protiprávním jednání (dále jen „Oznamovatel”), které porušuje příslušný právní předpis a&nbsp;tuto skutečnost hodlá oznámit či&nbsp;oznámil/a společnosti MND Energie a.s. (dále jen „Oznámení“).</div>
          <div>Společnost MND Energie a.s. vylučuje přijímání oznámení od&nbsp;osob, které pro ni&nbsp;nevykonávají, či&nbsp;nevykonávali pracovní, nebo jinou obdobnou činnost.</div>
        </div>
        <h3 className="text-H4 my-3">Co&nbsp;můžete hlásit?</h3>
        <div>
          <div className="mb-2">Protiprávní jednání, které</div>
          <div className="mb-2">a) má znaky trestného činu,</div>
          <div className="mb-2">b) má znaky přestupku, za&nbsp;který zákon stanoví sazbu pokuty, jejíž horní hranice je alespoň 100&nbsp;000&nbsp;Kč,</div>
          <div className="mb-2">c) porušuje zákon č. 171/2023 Sb., o&nbsp;ochraně oznamovatelů, nebo</div>
          <div className="mb-2">d) porušuje jiný právní předpis nebo předpis Evropské unie v&nbsp;oblasti<b>*</b>.</div>
        </div>
        <h3 className="text-H4 my-3">Kdo bude řešit vaše oznámení?</h3>
        <div>
          <div className="mb-2">Společnost určila následující příslušné osoby: Manažer zákaznické zkušenosti (dále též jen “Řešitel”).</div>
          Hedvika Benešová<br/>
          Manažer zákaznické zkušenosti<br/>
          Tel: 770 198 615<br/>
          E-mail: <a className="text-exploratory-green-400" href="mailto:whistleblowing@mnd.cz">whistleblowing@mnd.cz</a><br/>
        </div>
        <h3 className="text-H4 my-3">Jak můžete oznámení podat?</h3>
        <div>
          <ul>
            <li>Prostřednictvím <a className="text-exploratory-green-400" href="https://www.app.nntb.cz/c/di42emvk" target="_blank">platformy NNTB</a>;</li>
            <li>Osobně přímo řešiteli podání ústního oznámení bude umožněno bez&nbsp;zbytečného odkladu (nejpozději do 14 dnů) po&nbsp;žádosti oznamovatele;</li>
            <li>Písemně poštou na&nbsp;adresu MND Energie a.s., Manažer zákaznické zkušenosti, Evropská 866/71, Vokovice, 160 00 Praha 6, s&nbsp;označením obálky „Whistleblowing – neotevírat“;</li>
            <li>Telefonicky na čísle 770&nbsp;198&nbsp;615 (pouze v&nbsp;pracovní době PO–PÁ 9:00–17:00).</li>
          </ul>
          <div className="mb-2">Ochrana oznamovatele se vztahuje na&nbsp;každého Oznamovatele, který učiní Oznámení a&nbsp;splňuje Zákonem stanovená kritéria, tj. zejména, že má oprávněné důvody se domnívat, že se jeho Oznámení zakládá na&nbsp;pravdivých informacích.</div>
          <div className="mb-2">Platforma NNTB je preferovaným způsobem podání Oznámení, neboť Oznamovateli poskytuje největší kontrolu nad&nbsp;řešením oznámení a&nbsp;nejlépe zajišťuje jeho anonymitu, pokud o&nbsp;ni&nbsp;požádá.</div>
          <div className="mb-2">Všechna obdržená Oznámení budou objektivně a&nbsp;nezávisle prošetřena k&nbsp;tomu určeným Řešitelem. Na&nbsp;základě zjištěných skutečností budou v&nbsp;případě potřeby přijímána nápravná opatření.</div>
          <div className="mb-2">Řešitel informuje Oznamovatele o&nbsp;přijetí oznámení do&nbsp;7 dnů od&nbsp;jeho přijetí a&nbsp;oznámení posoudí do&nbsp;30 dnů od&nbsp;jeho přijetí. Ve&nbsp;skutkově či&nbsp;právně složitých případech může Řešitel oznámení tuto lhůtu prodloužit až o&nbsp;30 dnů, nejvýše však dvakrát.</div>
        </div>
        <h3 className="text-H4 my-3">Oznámení Ministerstvu spravedlnosti</h3>
        <div>
          V&nbsp;chráněném režimu Zákona lze oznamovat určená protiprávní jednání také Ministerstvu spravedlnosti, a&nbsp;to&nbsp;na&nbsp;webové adrese <a className="text-exploratory-green-400" href="https://oznamovatel.justice.cz/chci-podat-oznameni/" target="_blank">https://oznamovatel.justice.cz/chci-podat-oznameni/</a>
        </div>

        <div className="h-[1px] my-6 bg-[#E6E6E7] w-full"></div>

        <div className="text-[14px] italic">
          <b>*</b>  1. finančních služeb, povinného auditu a&nbsp;jiných ověřovacích služeb, finančních produktů a&nbsp;finančních trhů, 2. daně z&nbsp;příjmů právnických osob, 3. předcházení legalizaci výnosů z&nbsp;trestné činnosti a&nbsp;financování terorismu, 4. ochrany spotřebitele, 5. souladu s&nbsp;požadavky na&nbsp;výrobky včetně jejich bezpečnosti, 6. bezpečnosti dopravy, přepravy a&nbsp;provozu na&nbsp;pozemních komunikacích, 7. ochrany životního prostředí, 8. bezpečnosti potravin a&nbsp;krmiv a&nbsp;ochrany zvířat a&nbsp;jejich zdraví, 9. radiační ochrany a&nbsp;jaderné bezpečnosti, 10. hospodářské soutěže, veřejných dražeb a&nbsp;zadávání veřejných zakázek, 11. ochrany vnitřního pořádku a&nbsp;bezpečnosti, života a&nbsp;zdraví, 12. ochrany osobních údajů, soukromí a&nbsp;bezpečnosti sítí elektronických komunikací a&nbsp;informačních systémů, 13. ochrany finančních zájmů Evropské unie2), nebo 14. fungování vnitřního trhu včetně ochrany hospodářské soutěže a&nbsp;státní podpory podle práva Evropské unie.
        </div>
      </div>
    </MainLayout>
  );
};
